export default function Header() {
  return (
    <header className="px-5 mt-[100px] flex flex-col justify-center items-center">
      <div className="flex flex-col items-center relative w-full max-w-[500px] mx-auto ">
        <div className="flex flex-col items-center relative w-full max-w-[500px] mx-auto z-10">

          <div className="flex justify-center items-center flex-col w-fit border-b-2 border-dashed border-secondary pb-4 mb-5 text-secondary">
            <img src="/assets/brand/quiz-logo.svg" alt="quiz" className="w-[400px]" />
            <h1 className="text-4xl tracking-[13px]	font-bebas-bold uppercase mt-8 text-center">Santé Sécurité</h1>
          </div>
          <p className="font-bebas-regular uppercase text-xl text-center tracking-[5px]">DU 02 AU 30 JUIN 2023</p>


        </div>
      </div>
      <div className="relative">
        <h1 className="font-courrier uppercase text-secondary text-5xl md:text-[70px] mt-[50px] mb-[40px] z-10 relative text-center">
          réponses
        </h1>
        <div
          className="w-[250px] h-[25px] md:w-[400px] md:h-[30px] bg-primary absolute bottom-9 left-1/2 transform -translate-x-1/2"
        />
      </div>
    </header>
  );
}
