const questions = [
  {
    type: "text",
    title: "Qu’est ce qu’un TMS ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Trouble Musculo-Squelettique",
        isGood: true,
      },
      {
        title: "Trouble Musculo-Supérieur",
        isGood: false,
      },
      {
        title: "Trouble de la Mobilité et Sécurité",
        isGood: false,
      },
      {
        title: "Trouble de Moyenne Sensorialité",
        isGood: false,
      },
      {
        title: "Trouble des Molaires Sensibles",
        isGood: false,
      },
    ],
    correction: "Les Troubles Musculo-Squelettiques sont des maladies qui touchent les articulations, les muscles et les tendons. L’activité professionnelle peut jouer un rôle dans leur apparition, leur durée ou leur aggravation et de nombreuses entreprises sont concernées.",
    sources: [
      "https://www.ameli.fr/seine-saint-denis/assure/sante/themes/tms/comprendre-troubles-musculosquelettiques",
    ],
  },
  {
    type: "text",
    title: "Le dispositif interne de Reconnaissance de la Qualité de Travailleur Handicapé (RQTH) s’adresse à toute personne en situation de handicap. Il se traduit par des :",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Mesures techniques (aide au financement de prothèses auditives, EPI adaptés…)",
        isGood: true,
      },
      {
        title: "Mesures d’aménagement (horaires, ergonomie de poste de travail…)",
        isGood: true,
      },
      {
        title: "Mesures d’accompagnement (bilans de compétences, formations…)",
        isGood: true,
      },
      {
        title: "Mesures financières (tickets restaurants supplémentaires, aide au financement des vacances…)",
        isGood: false,
      },
      {
        title: "Mesures de mobilité (transport adapté…)",
        isGood: true,
      },
    ],
    correction: "Bouygues Energies et Services met à disposition une plaquette sur le sujet du handicap. Retrouvez-la via le lien ci-dessous.",
    sources: [
      "https://bouyguesconstruction.sharepoint.com/sites/byes/rh/Documents%20partages/Forms/AllItems.aspx?id=%2Fsites%2Fbyes%2Frh%2FDocuments%20partages%2F08%2E%20Engagement%20humain%2FA%20CLASSER%2F03%2E%20Handicap%2FPlaquette%20handicap%202023%20digital%20pdf%2Epdf&parent=%2Fsites%2Fbyes%2Frh%2FDocuments%20partages%2F08%2E%20Engagement%20humain%2FA%20CLASSER%2F03%2E%20Handicap",
    ],
  },
  {
    type: "grid",
    title: "Classer dans l’ordre croissant les parties du corps les plus touchées par les TMS",
    subtitle: "Plusieurs réponses possibles",
    columns: 5,
    options: [
      {
        title: "Épaule",
        value: 2,
      },
      {
        title: "Bas du dos",
        value: 4,
      },
      {
        title: "Genou",
        value: 5,
      },
      {
        title: "Coude",
        value: 3,
      },
      {
        title: "Main, poignet, doigts",
        value: 1,
      }
    ],
    correction: "",
    sources: [
      "",
    ],
  },
  {
    type: "text",
    title: "Quels sont les facteurs qui favorisent les TMS ?",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Le stress",
        isGood: true,
      },
      {
        title: "Le travail assis prolongé sur écran",
        isGood: true,
      },
      {
        title: "Le travail répétitif",
        isGood: true,
      },
      {
        title: "Une activité physique régulière",
        isGood: false,
      },
      {
        title: "Une activité prolongée en posture contraignante",
        isGood: true,
      },
      {
        title: "Les antécédents médicaux",
        isGood: true,
      },
    ],
    correction:
      `Les TMS ont une origine multifactorielle :<br/>
      - Les facteurs individuels: on parle ici d’âge, du sexe, des antécédents médicaux et de la génétique. <br/>
      - Les facteurs organisationnels: il s’agit de facteurs tels que les cycles de travail (rotations), la présence de coactivité, la répartition de tâches, les consignes non adaptées ou la mauvaise communication. <br/>
      - Les facteurs cognitifs: surcharge, forte pression temporelle, absence de marge de manœuvre, temps de récupération trop court, stress…<br/>
      - Les facteurs techniques : poste de travail, outils, les matières premières, les équipements...<br/>
      - Les facteurs de risque : force, répétition, fatigue, posture et froid / vibrations.`,
    sources: [
      "https://bouyguesconstruction.sharepoint.com/sites/byes/sante-securite/Documents%20partages/Forms/AllItems.aspx?id=%2Fsites%2Fbyes%2Fsante%2Dsecurite%2FDocuments%20partages%2FErgonomie%2FErgo%2Dtraining%2FERGO%20TRAINING%5Fmodule%20de%20formation%20BYES%20AXIONE%2Epdf&parent=%2Fsites%2Fbyes%2Fsante%2Dsecurite%2FDocuments%20partages%2FErgonomie%2FErgo%2Dtraining",
    ],
  },
  {
    type: "text",
    title: "Les TMS peuvent-ils apparaître en restant immobile ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Oui",
        isGood: true,
      },
      {
        title: "Non",
        isGood: false,
      }
    ],
    correction: "La sédentarité (absence de mouvement) est un facteur de risque d’apparition des TMS mais les études montrent que les postures dites sédentaires représentent un facteur de risque de nombreuses pathologies : maladies cardiovasculaires, diabète de type 2, obésité, cancer, troubles de la santé mentale... On observe ainsi une augmentation sensible du taux de mortalité chez les personnes les plus exposées aux postures sédentaires.",
    sources: [
      "https://www.inrs.fr/risques/tms-troubles-musculosquelettiques/facteurs-risque.html",
    ],
  },
  {
    type: "text",
    title: "Au niveau national, en 2019, quel a été le nombre de maladies professionnelles liées aux TMS et reconnues par le régime général ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "64 492",
        isGood: false,
      },
      {
        title: "44 492",
        isGood: true,
      },
      {
        title: "24 492",
        isGood: false,
      },
      {
        title: "Les chiffres ne sont jamais publiés",
        isGood: false,
      }
    ],
    correction: "",
    sources: [
      "https://www.santepubliquefrance.fr/les-actualites/2022/troubles-musculo-squelettiques-dans-le-secteur-de-la-sante-humaine-et-de-l-action-sociale-mieux-connaitre-les-facteurs-de-risque-pour-mieux-les-p",
    ],
  },
  {
    type: "text",
    title: "Depuis 2019, combien de maladies professionnelles ont été reconnues chez Axione et prises en charge par l’assurance maladie ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "5",
        isGood: false,
      },
      {
        title: "8",
        isGood: true,
      },
      {
        title: "12",
        isGood: false,
      },
      {
        title: "15",
        isGood: false,
      },
    ],
    correction: "Huit cas ont été reconnus chez Axione, chacun d’entre eux étant lié à des Troubles Musculo-Squelettiques. Cela nous montre que nous sommes toutes et tous concernés par ce sujet des TMS.",
    sources: [],
  },
  {
    type: "text",
    title: "En 2022, quelle est la part des Accidents de Travail Santé* sur le nombre total d’Accident du Travail ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "7%",
        isGood: false,
      },
      {
        title: "25%",
        isGood: false,
      },
      {
        title: "40%",
        isGood: true,
      },
      {
        title: "60%",
        isGood: false,
      },
    ],
    correction: "Sur 150 accidents de travail chez Axione en 2022, 59 étaient des ATS (soit 39%) dont 69% avec arrêt de travail (soit 1 181 jours).<br/><br/>Source: Bilan accidentologie Axione 2022",
    sources: [
      "",
    ],
  },
  {
    type: "text",
    title: "Quelles sont les charges maximales qui peuvent être portées par une personne seule chez Axione ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "10 kg",
        isGood: false,
      },
      {
        title: "15 kg",
        isGood: false,
      },
      {
        title: "20 kg",
        isGood: false,
      },
      {
        title: "25 kg",
        isGood: true,
      },
    ],
    correction: "Il faut toujours privilégier un moyen de manutention mécanisé même si la charge est inférieure à 25kg.",
    sources: [
      "",
    ],
  },
  {
    type: "text",
    title: "Les disques intervertébraux sont aux vertèbres :",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Ce que les lacets sont aux chaussures",
        isGood: false,
      },
      {
        title: "Ce que les amortisseurs sont à la voiture",
        isGood: true,
      },
      {
        title: "Ce que le sel est au poivre",
        isGood: false,
      },
      {
        title: "Ce que les protections auditives sont au bruit",
        isGood: false,
      },
    ],
    correction: "Le disque intervertébral est situé entre deux corps vertébraux osseux successifs, il joue le rôle de disque amortisseur. Il est composé de ligaments circulaires concentriques enserrant en leur centre un noyau liquide maintenu sous pression. Cette structure réalise un véritable système amortisseur entre les vertèbres. La qualité d’amortissement du disque est assurée par le maintien de la pression du liquide contenu dans le noyau.",
    sources: [
      "https://www.lombalgie.fr/",
    ],
  },
  {
    type: "images",
    title: "Quelle est la position la plus optimale pour le dos en cas de transport de charge répété ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Position 1",
        path: "/assets/correction/position1-1.png",
        isGood: false,
      },
      {
        title: "Position 2",
        path: "/assets/correction/position1-2.png",
        isGood: true,
      },
    ],
    correction: "Garder le dos aussi droit que possible est essentiel car, quelle que soit la charge à soulever, ramasser une pièce de 1€ peut provoquer les mêmes dégâts que soulever un poids de 50 kilos.",
    sources: [
      "https://www.aist84.fr/la-manutention-pas-sans-risques/",
    ],
  },
  {
    type: "images",
    title: "Quelles sont les postures à privilégier lorsque l’on travaille plusieurs heures par jour sur un écran ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Position 1",
        path: "/assets/correction/question-12-1.png",
        isGood: false,
      },
      {
        title: "Position 2",
        path: "/assets/correction/question-12-2.png",
        isGood: false,
      },
      {
        title: "Position 3",
        path: "/assets/correction/question-12-3.png",
        isGood: true,
      },
      {
        title: "Position 4",
        path: "/assets/correction/question-12-4.png",
        isGood: true,
      },
      {
        title: "Position 5",
        path: "/assets/correction/question-12-5.png",
        isGood: false,
      },
      {
        title: "Position 6",
        path: "/assets/correction/question-12-6.png",
        isGood: true,
      },
    ],
    correction: "Pour adopter une posture optimale au bureau, veillez à : <br/>- Régler votre écran pour que sa hauteur soit adaptée <br/>- Régler votre siège de manière à ce que vos coudes soient à la même hauteur que le plan de travail et utiliser un repose-pied <br/>- Régler la hauteur du dossier de votre chaise pour que le bassin et la zone lombaire soient soutenus efficacement <br/>- Utiliser un réhausseur pour votre PC portable <br/>- Avoir une distance œil/écran de 70cm (cette distance s’évalue en tendant votre bras) <br/>- Avoir une distance genou/table de 8 à 10cm <br/>- Adopter une posture correcte : Épaules relachés, avant-bras horizontaux avec un angle des coudes légèrement supérieur à 90°, les pieds posés au sol ou sur un réhausseur, occuper la totalité de la surface assise et caler le dos contre le dossier de la chaise <br/>Pour aller plus loin : retrouvez le module de sensibilisation BYES Ergonomie « Comment organiser mon poste de travail au bureau ? » disponible sur By Le@rn",
    sources: [
      "https://bylearn.syfadis.com/Catalog/TrainingShops/TrainingView.aspx?idTraining=2689761281",
    ],
  },
  {
    type: "images",
    title: "Quelles sont les postures à privilégier lorsque l’on travaille plusieurs heures par jour sur un écran ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Situation 1",
        path: "/assets/correction/question-13-1.png",
        isGood: true,
      },
      {
        title: "Situation 2",
        path: "/assets/correction/question-13-2.png",
        isGood: true,
      },
      {
        title: "Situation 3",
        path: "/assets/correction/question-13-3.png",
        isGood: false,
      },
      {
        title: "Situation 4",
        path: "/assets/correction/question-13-4.png",
        isGood: false,
      },
      {
        title: "Situation 5",
        path: "/assets/correction/question-13-5.png",
        isGood: false,
      },
      {
        title: "Situation 6",
        path: "/assets/correction/question-13-6.png",
        isGood: true,
      },
    ],
    correction:
      `Les facteurs présents, par exemple, sur ces situations :<br/>
      - Les postures coûteuses en énergie: bras au-dessus des épaules<br/>
      - Les efforts nécessitant de la force : le tirage de câble manuel<br/>
      - La répétition des actions<br/>
      - Les conditions météorologiques (froid, humidité…)<br/><br/>

      Source : SharePoint Ergonomie BYES ou voir votre Préventeur référent`,
    sources: [],
  },
  {
    type: "text",
    title: "Quelles actions peuvent soulager votre dos au quotidien ?",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Pratiquer une activité physique, pour mieux ressentir la position de son dos",
        isGood: true,
      },
      {
        title: "Bien utiliser le matériel, communiquer et former les salariés sur les outils",
        isGood: true,
      },
      {
        title: "Demander à un collègue de réduire la musique dans son casque",
        isGood: false,
      },
      {
        title: "Choisir des engins bien équipés, avec une attention particulière sur le choix des sièges et les dispositifs d’aide à la conduite d'engins",
        isGood: true,
      },
    ],
    correction: "",
    sources: [
      "https://www.preventionbtp.fr/ressources/documentation/affiche/quatre-conseils-pour-soulager-le-dos-chez-soi-ou-sur-le-terrain_fjvo2ZVPUhg5frnfskSnMV",
    ],
  },
  {
    type: "text",
    title: "Bouygues Construction a mis à disposition une plateforme collaborative et évolutive pour recenser des outils, du matériel et tout autre solution ergonomique appropriée à nos besoins. Comment se nomme-t-elle ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "La Gourou Plateforme : Une plateforme magique",
        isGood: false,
      },
      {
        title: "Le TMS Stadium : Toutes les solutions contre les TMS s’y retrouvent",
        isGood: false,
      },
      {
        title: "L’Ergo’APP : Un besoin ergonomique = une solution pratique",
        isGood: true,
      },
      {
        title: "L’ENEDIS : ErgoNomie Express’ à destination des Infrastructures Salutaires",
        isGood: false,
      },
    ],
    correction: "Bouygues Construction met à disposition l’Ergo’APP, qui est enrichie mensuellement et qui est à destination de tous. Vous pourrez y retrouver des informations/articles utiles concernant les aides à la manutention, les dispositifs d’assistance physique, les équipements et outillages…",
    sources: [
      "https://ergoapp.bouygues-construction.com/?lang=fr",
    ],
  },
  {
    type: "text",
    title: "Quels principaux avantages permettent d’apporter la prise en compte de l’ergonomie lors de la conception des postes de travail ?",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Accroître la productivité",
        isGood: true,
      },
      {
        title: "Accroître la résistance aux chocs",
        isGood: false,
      },
      {
        title: "Optimiser les conditions de travail pour préserver la santé",
        isGood: true,
      },
      {
        title: "Réduire le stress",
        isGood: false,
      },
      {
        title: "Améliorer la satisfaction des travailleurs",
        isGood: true,
      },
    ],
    correction: "",
    sources: [
      "https://bouyguesconstruction.sharepoint.com/sites/byes/sante-securite/Documents%20partages/Forms/AllItems.aspx?id=%2Fsites%2Fbyes%2Fsante%2Dsecurite%2FDocuments%20partages%2FErgonomie%2FMemento%20Ergonomie%20BYES%20issu%20BYCN%20v2%20F%C3%A9v2017%2Epdf&parent=%2Fsites%2Fbyes%2Fsante%2Dsecurite%2FDocuments%20partages%2FErgonomie",
    ],
  },
  {
    type: "text",
    title: "Après avoir consulté le médecin traitant, ce dernier vous a prescrit un traitement avec la prise régulière d’un antalgique. Sur la boîte de ce médicament se trouve ce pictogramme :<br/><img src='/assets/correction/question-18-pretitle.png' alt='medicament-picto' /> <br/>Qu’est-ce que cela signifie ? ",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Il est nécessaire de lire attentivement la notice avant reprise de la conduite. Il ne sera pas nécessaire de demander l’avis du médecin",
        isGood: false,
      },
      {
        title: "La prise de ce médicament rend la conduite dangereuse. Il est nécessaire de demander l’avis du médecin avant reprise de la conduite",
        isGood: true,
      },
      {
        title: "La prise du médicament peut remettre en cause la capacité à conduire. Cependant il n’est pas nécessaire de demander l’avis d’un professionnel de santé",
        isGood: false,
      },
      {
        title: "La prise de ce médicament demande une forme physique importante pour maîtriser le véhicule",
        isGood: false,
      },
    ],
    correction:
      `
Certains médicaments peuvent altérer les capacités de conduite ou l’utilisation de machines :<br/><br/>

Niveau 3 – Niveau rouge <br/>
- Conduire est interdit : soit car le médicament fait dormir, soit parce qu’il altère votre vision.<br/>
- Demandez à votre médecin ou à votre ophtalmologue le délai nécessaire entre l’arrêt du traitement et la possibilité de reprendre le volant.<br/>
- On retrouve dans cette catégorie : somnifères, tranquillisants, ainsi que certains collyres utilisés lors d’examens d’ophtalmologie.<br/><br/>

Niveau 2 – Niveau orange<br/>
- La conduite peut être remise en cause : il est indispensable d’en parler avec un professionnel de santé.<br/>
- Vérifier votre aptitude à la conduite avec votre médecin ou votre pharmacien.<br/>
- On retrouve dans cette catégorie : certains antidépresseurs, des antidouleurs (notamment ceux à base de codéine), certains médicaments contre les allergies ou le rhume des foins …<br/><br/>

Niveau 1 – Niveau jaune<br/>
- La conduite est possible, mais il faut rester vigilant car certains médicaments peuvent parfois entraîner une somnolence ou des vertiges.<br/>
- Ne prenez pas la voiture si vous ressentez ces symptômes.<br/>
- Ils peuvent apparaître si vous prenez des traitements antihypertenseurs ou certains traitements contre la toux.`,
    sources: [
      "https://www.medicament-info-service-patient.fr/info-medicament/que-signifient-les-pictogrammes-sur-les-boites-de-medicaments/",
    ],
  },
  {
    type: "text",
    title: "À la suite d’un effort (un poids soulevé par exemple), une douleur commence à se ressentir du bas du dos vers les membres inférieurs. Quelles actions puis-je mener pour la soulager ?",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Se reposer au lit de manière prolongée",
        isGood: false,
      },
      {
        title: "En cas de position allongée, surélever les jambes",
        isGood: true,
      },
      {
        title: "Consulter un professionnel de santé",
        isGood: true,
      },
      {
        title: "Rester assis le plus longtemps possible tout en mettant ses pieds sur une zone froide pour réduire la sensation d’engourdissement",
        isGood: false,
      },
      {
        title: "Effectuer des étirements (garder une activité)",
        isGood: true,
      },
      {
        title: "Faire de la musculation pour traiter le mal par le mal",
        isGood: false,
      },
    ],
    correction:
      `Certaines crises de sciatique peuvent être traitées via de l’étirement et une alternance activité/repos. Cependant dans certains cas de névralgie du nerf, il sera nécessaire de consulter en urgence un médecin. Ci-dessous quelques symptômes devant faire alerte :<br/>
- Vous constatez une paralysie ou une baisse de la force musculaire de votre jambe ;<br/>
- Vous remarquez une perte de sensibilité de votre périnée (zone située entre l'anus et les organes génitaux externes) ;<br/>
- Vous avez des difficultés pour uriner ou des fuites urinaires ;<br/>
- Vous avez une perte du contrôle du sphincter anal ou au contraire une constipation inhabituelle ;<br/>
- Vous présentez ou avez présenté récemment l'un des symptômes suivants : fièvre, difficultés à respirer, vomissements, infection urinaire...`,
    sources: [
      "https://www.ameli.fr/assure/sante/themes/sciatique/que-faire-quand-consulter#:~:text=%C3%A9vitez%20les%20mauvaises%20positions%20et,adaptez%20vos%20activit%C3%A9s%2C%20sans%20forcer",
    ],
  },
  {
    type: "text",
    title: "Qu’est-ce que le syndrome du canal carpien ?",
    subtitle: "Une seule réponse possible",
    options: [
      {
        title: "Un gonflement important au niveau de la malléole",
        isGood: false,
      },
      {
        title: "Une série de syndromes résultant de la compression du nerf au niveau du poignet",
        isGood: true,
      },
      {
        title: "Une hypersensibilité à la lumière",
        isGood: false,
      },
      {
        title: "Une envie incontrôlable d’aller à la pêche",
        isGood: false,
      },
    ],
    correction: "Les symptômes dus à la compression du nerf médian dans le canal carpien apparaissent au niveau des trois premiers doigts de la main : fourmillements, douleurs, troubles de la sensibilité puis diminution de la force. L'examen clinique souvent complété d'un électroneuromyogramme permet de poser le diagnostic de syndrome du canal carpien.",
    sources: [
      "https://www.ameli.fr/assure/sante/themes/syndrome-canal-carpien/symptomes-diagnostic-evolution#:~:text=Les%20sympt%C3%B4mes%20du%20syndrome%20du%20canal%20carpien&text=un%20engourdissement%20progressif%20et%20une,vers%20l%27avant%2Dbras",
    ],
  },
  {
    type: "text",
    title: "Comment s’en prémunir ?",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Veiller à ce que ses poignets n’appuient pas sur des surfaces dures durant de longues périodes",
        isGood: true,
      },
      {
        title: "Faire rouler une balle de tennis dans la paume de ses mains",
        isGood: false,
      },
      {
        title: "Étirer les avant-bras et les poignets",
        isGood: true,
      },
      {
        title: "Veiller à tenir les objets à pleine main et non du bout des doigts",
        isGood: false,
      },
      {
        title: "Limiter les gestes répétitifs",
        isGood: true,
      },
      {
        title: "Masser régulièrement ses dernières phalanges avec des huiles essentielles",
        isGood: false,
      },
    ],
    correction: "",
    sources: [
      "https://www.assurance-prevention.fr/canal-carpien-prevention.html",
    ],
  },
  {
    type: "text",
    title: "Sélectionnez les affirmations correctes :",
    subtitle: "Plusieurs réponses possibles",
    options: [
      {
        title: "Incliner légèrement son clavier permet de soulager ses poignets",
        isGood: false,
      },
      {
        title: "Une souris verticale permet à la main d’adopter une position naturelle",
        isGood: true,
      },
      {
        title: "Un objet/matériel ergonomique est adapté à tout le monde",
        isGood: false,
      },
      {
        title: "C’est sous le régime de RAMSES II, en Egypte, qu’ont été inventées les électrodes permettant de soulager les douleurs, juste après la découverte de l’électricité",
        isGood: false,
      },
      {
        title: "Les ceintures lombaires médicales ne permettent pas de prévenir les maux de dos",
        isGood: true,
      },
    ],
    correction: "Les ceintures lombaires, sont utilisées lorsque la pathologie se manifeste, il est trop tard. Garder le dos droit, bouger, avoir une bonne hygiène de vie, c’est ce qui permet d’éviter les futurs problèmes de dos.",
    sources: [
      "https://bouyguesconstruction.sharepoint.com/sites/byes/sante-securite/SitePages/04%20ERGO_Le-Travail-sur-Ecran.aspx",
    ],
  },
];

export default questions;


const test =
{
  type: "text",
  title: "",
  subtitle: "Plusieurs réponses possibles",
  options: [
    {
      title: "",
      isGood: false,
    }
  ],
  correction: "",
  sources: [
    "",
  ],
}

/*

  {
    title: "",
    subtitle: "",
    options: [
      {
        title: "",
        response: {
          isGood: false,
          reason: "",
        },
      },
    ],
    sources: [""],
  },

  */
