import { Route, Routes } from "react-router";

import HomePage from "features/home/home.page";
import CorrectionPage from "features/correction/correction.page";

export default function RootContainer() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/reponses" element={<CorrectionPage />} />
    </Routes>
  );
}
