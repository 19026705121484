import { Navbar } from "components/organisms";
import Header from "./_components/header";
import questions from "utils/questions";
import Question from "./_components/question";
export default function CorrectionPage() {
  return (
    <div>
      <Navbar />
      <Header />

      <ul className="max-w-[900px] m-auto px-10">
        {questions.map((question, index) => (
          <li key={index} className="my-20">
            <Question
              question={question}
              position={index + 1}
              total={questions.length}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
