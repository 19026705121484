const winners = [
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Simon",
    "lastname": "Maurin",
    "gift": "Coffrets Rêve de bien-être (massage en duo)"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "STEPHANIE",
    "lastname": "ZAPATERO",
    "gift": "Coffrets Rêve de bien-être (massage en duo)"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Bérengere",
    "lastname": "d'Ausbourg",
    "gift": "Ballons ergo"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Emilie",
    "lastname": "Desrousseaux",
    "gift": "Ballons ergo"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Thomas",
    "lastname": "LESAGE",
    "gift": "Réhausseur ordinateur portable "
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "vanessa",
    "lastname": "iscla",
    "gift": "Réhausseur ordinateur portable "
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Clémence",
    "lastname": "Caussegal",
    "gift": "Coffrets sensation bien-être"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "JOSEPHINE",
    "lastname": "JOURDIN",
    "gift": "Coffrets sensation bien-être"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Patrick ",
    "lastname": "Fortuné ",
    "gift": "Coffrets massage têtes aux pieds"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Paul",
    "lastname": "STAUB",
    "gift": "Coffrets massage têtes aux pieds"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Sarah",
    "lastname": "MAZZILLI",
    "gift": "Masseurs manuels"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Clémentine",
    "lastname": "ROCHE",
    "gift": "Masseurs manuels"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Camille",
    "lastname": "Pilté",
    "gift": "Mini ceintures lombaires"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Elisa",
    "lastname": "JULIA",
    "gift": "Mini ceintures lombaires"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Ahcene ",
    "lastname": "NAIT ABDESSELAM",
    "gift": "Coffrets infusions à composer"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Koundou",
    "lastname": "Konté",
    "gift": "Coffrets infusions à composer"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Laura",
    "lastname": "Romieux",
    "gift": "Souris ergonomique"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Sabine",
    "lastname": "Dandurand",
    "gift": "Souris ergonomique"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "JEREMY",
    "lastname": "SAR",
    "gift": "Diffuseurs d'huiles essentielles"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Emilie ",
    "lastname": "Hubert",
    "gift": "Diffuseurs d'huiles essentielles"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Jérôme ",
    "lastname": "Berruer",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Kévin",
    "lastname": "GIRARD",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Said",
    "lastname": "AIT KHALED",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Ophélie",
    "lastname": "LOUBAT",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "celina",
    "lastname": "abdelli",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "Yvann",
    "lastname": "Vergne",
    "gift": "Non tiré au sort"
  },
  {
    "score": 25,
    "cup": "gold",
    "firstname": "thierry",
    "lastname": "allo",
    "gift": "Non tiré au sort"
  },
  {
    "score": "24",
    "cup": "silver",
    "firstname": "Yassine",
    "lastname": "BENLAHSSANIA",
    "gift": ""
  },
  {
    "score": "24",
    "cup": "silver",
    "firstname": "Richard",
    "lastname": "JEANDENANT",
    "gift": ""
  },
  {
    "score": "24",
    "cup": "silver",
    "firstname": "Pierre",
    "lastname": "Vallernaud",
    "gift": ""
  },
  {
    "score": "24",
    "cup": "silver",
    "firstname": "Mustapha",
    "lastname": "OUHADROU",
    "gift": ""
  },
  {
    "score": "24",
    "cup": "silver",
    "firstname": "Sandrine",
    "lastname": "PICANT",
    "gift": ""
  },
  {
    "score": "23",
    "cup": "bronze",
    "firstname": "Frédérique",
    "lastname": "LEFEVRE",
    "gift": ""
  },
  {
    "score": "23",
    "cup": "bronze",
    "firstname": "Gaëlle",
    "lastname": "FREROU",
    "gift": ""
  },
  {
    "score": "23",
    "cup": "bronze",
    "firstname": "ludovic",
    "lastname": "augustine",
    "gift": ""
  }
];


export default winners;
