import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="px-5 min-h-screen flex flex-col justify-center items-center relative">
      <div className="flex flex-col items-center relative w-full max-w-[500px] mx-auto z-10">

        <div className="flex justify-center items-center flex-col w-fit border-b-2 border-dashed border-secondary pb-4 mb-5 text-secondary">
          <img src="/assets/brand/quiz-logo.svg" alt="quiz" className="w-[400px]" />
          <h1 className="text-4xl tracking-[13px]	font-bebas-bold uppercase mt-8 text-center">Santé Sécurité</h1>
        </div>
        <p className="font-bebas-regular uppercase text-xl text-center tracking-[5px]">DU 02 AU 30 JUIN 2023</p>


      </div>


      <div className="relative w-full  pb-[100px] z-10">
        <h2 className="font-hurme-bold text-[25px] text-secondary text-center mt-[60px]">
          Merci d’avoir participé à notre jeu concours !
        </h2>

        <p className="font-hurme-regular text-[20px] text-secondary text-center mt-[60px]">
          Pour visualiser les bonnes réponses,{" "}
          <Link
            to="/reponses"
            className="font-hurme-bold text-secondary inline-block relative group"
          >
            <span className="relative z-10">cliquez ici.</span>
            <div className="w-[110px] h-[9px] bg-primary absolute bottom-0.5 left-1/2 
            transform -translate-x-1/2 group-hover:h-[30px] transition-all duration-300 ease-in-out" />
          </Link>
        </p>

      </div>

      <div className="flex flex-col items-center gap-3 z-10 absolute bottom-0">
        <p className="font-bebas-regular text-secondary text-[20px] tracking-[2px]">
          Les scores
        </p>
        <img src="/assets/icons/scroll.svg" alt="" />
        <div className="h-[70px] w-[1px] border-r border-dashed border-secondary" />
      </div>

      <img
        src="/assets/illustrations/header-left.png"
        alt=""
        className="absolute left-20 bottom-1/2 w-[30%] max-w-[400px] transform translate-y-1/2 translate-x-[-50%] hidden md:block"
      />
      <img
        src="/assets/illustrations/header-right.png"
        alt=""
        className="absolute right-20 top-[200px] w-[20%] max-w-[250px] hidden md:block"
      />
    </header>
  );
}
