export default function Question({ question, position = 1, total = 15 }) {
  const getUrlSource = (source) => {
    if (source.startsWith("http")) {
      return source;
    } else {
      return `/assets/files/${source}`;
    }
  };

  return (
    <div className="border border-black px-5 md:px-10 md:py-12 relative pt-12 pb-5">
      <p className="w-[110px] h-[50px] absolute top-0 bg-primary flex justify-center items-center text-secondary text-[20px] font-hurme-regular -translate-y-1/2 -translate-x-[10px]">
        <span className="text-[30px] font-hurme-semibold">
          {position < 10 ? `0${position}` : position}
        </span>
        /{total}
      </p>
      <h1 className="font-hurme-bold text-[25px] leading-[30px] text-secondary" dangerouslySetInnerHTML={{ __html: question.title }} />
      {/* <h2 className="text-black opacity-30 font-hurme-regular text-[15px] mt-2">
        {question.subtitle}
      </h2> */}

      {/* RESPONSES */}
      {question.type === "grid" ? (
        <div className="overflow-x-auto">
          <div className="flex flex-col gap-5 min-w-[500px]">
            <div className={`grid grid-cols-[repeat(6,1fr)] items-center py-5 px-10 pb-0 gap-5`}>
              <p></p>
              {[...Array(question.columns)].map((_, index) => (
                <p className="text-center" key={index}>{index + 1}</p>
              ))}
            </div>
            {question.options.map((input, index) => (
              <div className={`grid grid-cols-[repeat(6,1fr)] gap-5 items-center odd:bg-primary/25 py-5 px-10 relative`}
                key={index}>
                <p>{input.title}</p>
                {[...Array(question.columns)].map((_, index) => (
                  <div
                    className={`h-[20px] w-[20px] border border-black m-auto ${input.value === index + 1 && "bg-primary"} `}
                  />

                ))}
              </div>
            ))}
          </div>
        </div>
      ) : question.type === "images" ? (
        <div className={`flex flex-col md:flex-row gap-5 md:gap-y-10 mt-6 mb-14
              ${question.options.length > 2 ? "md:grid md:grid-cols-3" : "md:grid md:grid-cols-2"}
              `}>
          {question.options.map((input, index) => (
            <p className={`relative rounded-xl  transition-all duration-300 ease-in-out
              ${input.isGood ? "border-8 border-primary bg-primary shadow-lg" : "border-8 border-transparent"}
            `}
              key={index}
            >
              <img src={input.path} alt={input.title} className="w-full h-full object-cover rounded-lg" />
              <p className="mt-4 text-center">
                {input.title}
              </p>
            </p>
          ))}
        </div>
      ) : (
        <ul>
          {question.options.map((option, index) => (
            <li key={index} className="flex gap-5 my-2 items-center">
              <div
                className={`h-[20px] min-w-[20px] w-[20px] border border-black ${option.isGood && "bg-primary"
                  }`}
              />
              <p className="font-hurme-regular text-[20px] mt-1">
                {option.title}
              </p>
            </li>
          ))}
        </ul>
      )
      }

      {/* CORRECTION */}
      <div className="w-full border-t border-dashed border-secondary my-5" />
      {
        question.correction.length > 0 && (
          <div className="flex flex-col gap-1">
            <h3 className="font-hurme-semibold text-lg leading-[30px] text-secondary">
              COMMENTAIRE :
            </h3>
            {/* dangerouslySetInnerHTML is used to render HTML */}
            <p className="font-hurme-regular text-md text-black" dangerouslySetInnerHTML={{ __html: question.correction }} />
          </div>
        )
      }

      {/* SOURCES */}
      <ul className="mt-5">
        {question.sources[0]?.length ? question.sources.map((source, index) => (
          <li key={index}>
            <a href={getUrlSource(source)} target="_blank" rel="noreferrer" className="text-gray-400 font-hurme-regular text-[15px] truncate flex items-center group bg-gray-100 inline-block px-4 py-1 pl-3 rounded-full w-fit border border-gray-400 gap-2
            ">
              <img src="/assets/icons/link.svg" alt="link" className="w-[22px]" />
              <span
                className="group-hover:underline text-blue-500 mt-[0.5px]"
              >
                source
              </span>
            </a>
          </li>
        )) : null}
      </ul>
    </div >
  );
}
