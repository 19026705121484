import winners from "utils/winners";

export default function Winners() {
  const getCupImg = (cup) => {
    switch (cup) {
      case "gold":
        return "/assets/icons/cup-gold.png";
      case "silver":
        return "/assets/icons/cup-silver.png";
      case "bronze":
        return "/assets/icons/cup-bronze.png";
      default:
        return "/assets/icons/cup-gold.png";
    }
  };

  const getCupColor = (cup) => {
    switch (cup) {
      case "gold":
        return "#E6B100";
      case "silver":
        return "#A8A8A8";
      case "bronze":
        return "#A4814D";
      default:
        return "#A4814D";
    }
  };

  return (
    <section className="-mt-[95px]">
      <div className="max-w-[1000px] m-auto px-10 bg-primary flex items-center justify-center gap-10 pt-24 pb-10">
        <img src="/assets/illustrations/winner-left.svg" alt="" className="hidden md:block" />
        <h1 className="font-courrier text-3xl md:text-[45px] uppercase text-secondary text-center mt-10 md:mt-0">
          bravo<br />aux gagnants !
        </h1>
        <img src="/assets/illustrations/winner-right.svg" alt="" className="hidden md:block" />
      </div>
      <div className="">
        <ul className="max-w-[1000px] m-auto px-10 pt-14 bg-white">
          {winners.map((winner, index) => (
            <li
              key={index}
              className={`grid grid-cols-1 md:grid-cols-2 border-secondary border-dashed items-center pt-5 pb-5 gap-3 md:gap-0 ${index === winners.length - 1 ? "border-b-0" : "border-b"
                }`}
            >
              <div className="flex gap-10 items-center">
                <p
                  className="font-hurme-bold text-[25px] text-secondary-light"
                  style={{
                    color: getCupColor(winner.cup),
                  }}
                >
                  {winner.score}
                  <span className="text-[18px]">pts</span>
                </p>
                <img src={getCupImg(winner.cup)} alt="" className="h-[40px]" />
                <p className="font-bebas-regular font-bold text-[20px] text-brown tracking-widest">
                  {winner.firstname} {winner.lastname}
                </p>
              </div>
              <div>
                <p className="font-hurme-regular text-secondary text-[15px]">
                  {winner.gift}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="max-w-[1000px] m-auto px-10 bg-primary py-6">
      </div>
    </section>
  );
}
