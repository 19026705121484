import ranking from "utils/ranking";
export default function Ranking() {
  const scores = ranking.reduce((acc, winner) => {
    if (acc[winner.score]) {
      acc[winner.score].push(winner);
    } else {
      acc[winner.score] = [winner];
    }
    return acc;
  }, {});

  return (
    <div className="relative">
      <img src="/assets/illustrations/ranking-left.png" alt="" className="absolute w-[15%] max-w-[300px] top-1/2 left-0 min-w-[200px] md:block" />
      <img src="/assets/illustrations/ranking-right-1.png" alt="" className="absolute w-[15%] max-w-[300px] top-[5%] right-0 min-w-[200px] md:block" />
      <img src="/assets/illustrations/ranking-right-2.png" alt="" className="absolute w-[15%] max-w-[300px] bottom-[10%] right-0 min-w-[200px] md:block" />
      <section className="max-w-[900px] m-auto mt-[50px] z-10" id="scores">
        <div className="flex justify-center">
          <h1 className="font-courrier text-secondary text-[25px] text-center uppercase py-5">
            Classement général
          </h1>
        </div>
        <div className="m-auto px-10 mb-[100px]">
          <ul className="border border-secondary p-5 md:p-12 pb-0 relative bg-[#ffffff7a]">
            {Object.keys(scores)
              .reverse()
              .map((score) => {
                console.log(score);
                return (
                  <li key={score} className="mb-14">
                    <div className="flex items-center justify-between font-courrier bg-secondary-light text-secondary text-[25px] px-5 pt-1 gap-5 mb-8 bg-primary">
                      <h3 className="min-w-fit mb-1">{score} pts</h3>
                      <div className="w-full border-t border-secondary border-dashed" />
                    </div>

                    <div
                      className="grid grid-cols-2 md:grid-cols-3 gap-5
                  "
                    >
                      {scores[score]
                        .sort((a, b) => {
                          if (
                            a.firstname.toLowerCase() < b.firstname.toLowerCase()
                          )
                            return -1;
                          if (
                            a.firstname.toLowerCase() > b.firstname.toLowerCase()
                          )
                            return 1;
                          return 0;
                        })
                        .map((person, index) => (
                          <p
                            key={index}
                            className="font-bebas-regular text-secondary text-[16px] opacity-50"
                          >
                            {person.firstname} {person.lastname}
                          </p>
                        ))}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
    </div>
  );
}
