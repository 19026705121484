import { Navbar } from "components/organisms";
import Header from "./_components/header";
import Ranking from "./_components/ranking";
import Winners from "./_components/winners";

export default function HomePage() {
  return (
    <div>
      <Navbar />
      <Header />
      <Winners />
      <Ranking />
    </div>
  );
}
