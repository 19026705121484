import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export function Navbar() {
  return (
    <>
      <nav className="w-full md:h-[67px] bg-white fixed flex z-20 py-2">
        <div className="flex flex-col md:flex-row w-full max-w-[1200px] px-10 justify-between items-center m-auto gap-3">
          <Link
            to="/"
            className="flex items-center justify-between px-4 md:py-3"
          >
            <img
              src="/assets/brand/logo-nav.svg"
              alt="logo"
              className="w-[180px] md:w-auto"
            />
          </Link>
          <ul className="flex gap-5">
            <li>
              <HashLink
                smooth
                to="/#scores"
                className="font-courrier uppercase border-primary text-sm bg-primary border text-secondary h-[38px] w-[107px] flex justify-center items-center"
              >
                Scores
              </HashLink>
            </li>
            <li>
              <a
                href="/reponses"
                className="font-courrier uppercase border-secondary text-sm border text-secondary h-[38px] w-[107px] flex justify-center items-center hover:bg-secondary hover:text-white transition-all duration-300 ease-in-out"
              >
                Réponses
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="w-full h-[67px] bg-transparent" id="start" />
    </>
  );
}
