const ranking = [
  {
    "score": 25,
    "lastname": "ZAPATERO",
    "firstname": "STEPHANIE"
  },
  {
    "score": 25,
    "lastname": "LOUBAT",
    "firstname": "Ophélie"
  },
  {
    "score": 25,
    "lastname": "JULIA",
    "firstname": "Elisa"
  },
  {
    "score": 25,
    "lastname": "Romieux",
    "firstname": "Laura"
  },
  {
    "score": 25,
    "lastname": "Maurin",
    "firstname": "Simon"
  },
  {
    "score": 25,
    "lastname": "Dandurand",
    "firstname": "Sabine"
  },
  {
    "score": 25,
    "lastname": "Berruer",
    "firstname": "Jérôme"
  },
  {
    "score": 25,
    "lastname": "AIT KHALED",
    "firstname": "Said"
  },
  {
    "score": 25,
    "lastname": "MAZZILLI",
    "firstname": "Sarah"
  },
  {
    "score": 25,
    "lastname": "Fortuné",
    "firstname": "Patrick"
  },
  {
    "score": 25,
    "lastname": "Desrousseaux",
    "firstname": "Emilie"
  },
  {
    "score": 25,
    "lastname": "SAR",
    "firstname": "JEREMY"
  },
  {
    "score": 25,
    "lastname": "allo",
    "firstname": "thierry"
  },
  {
    "score": 25,
    "lastname": "iscla",
    "firstname": "vanessa"
  },
  {
    "score": 25,
    "lastname": "NAIT ABDESSELAM",
    "firstname": "Ahcene"
  },
  {
    "score": 25,
    "lastname": "Vergne",
    "firstname": "Yvann"
  },
  {
    "score": 25,
    "lastname": "JOURDIN",
    "firstname": "JOSEPHINE"
  },
  {
    "score": 25,
    "lastname": "abdelli",
    "firstname": "celina"
  },
  {
    "score": 25,
    "lastname": "LESAGE",
    "firstname": "Thomas"
  },
  {
    "score": 25,
    "lastname": "Hubert",
    "firstname": "Emilie"
  },
  {
    "score": 25,
    "lastname": "ROCHE",
    "firstname": "Clémentine"
  },
  {
    "score": 25,
    "lastname": "d'Ausbourg",
    "firstname": "Bérengere"
  },
  {
    "score": 25,
    "lastname": "Caussegal",
    "firstname": "Clémence"
  },
  {
    "score": 25,
    "lastname": "Pilté",
    "firstname": "Camille"
  },
  {
    "score": 25,
    "lastname": "GIRARD",
    "firstname": "Kévin"
  },
  {
    "score": 25,
    "lastname": "STAUB",
    "firstname": "Paul"
  },
  {
    "score": 25,
    "lastname": "Konté",
    "firstname": "Koundou"
  },
  {
    "score": 24,
    "lastname": "BENLAHSSANIA",
    "firstname": "Yassine"
  },
  {
    "score": 24,
    "lastname": "JEANDENANT",
    "firstname": "Richard"
  },
  {
    "score": 24,
    "lastname": "Vallernaud",
    "firstname": "Pierre"
  },
  {
    "score": 24,
    "lastname": "OUHADROU",
    "firstname": "Mustapha"
  },
  {
    "score": 24,
    "lastname": "PICANT",
    "firstname": "Sandrine"
  },
  {
    "score": 23,
    "lastname": "LEFEVRE",
    "firstname": "Frédérique"
  },
  {
    "score": 23,
    "lastname": "FREROU",
    "firstname": "Gaëlle"
  },
  {
    "score": 23,
    "lastname": "augustine",
    "firstname": "ludovic"
  },
  {
    "score": 22,
    "lastname": "GAUTIER",
    "firstname": "Clément"
  },
  {
    "score": 22,
    "lastname": "Troalain",
    "firstname": "Claire"
  },
  {
    "score": 22,
    "lastname": "FARES",
    "firstname": "LAIB"
  },
  {
    "score": 22,
    "lastname": "vanhoutte",
    "firstname": "julien"
  },
  {
    "score": 21,
    "lastname": "Esterez",
    "firstname": "Théo"
  },
  {
    "score": 21,
    "lastname": "Carlier",
    "firstname": "Julien"
  },
  {
    "score": 20,
    "lastname": "Heyraud",
    "firstname": "Olivier"
  },
  {
    "score": 20,
    "lastname": "PICHERAL",
    "firstname": "Berengere"
  },
  {
    "score": 20,
    "lastname": "LLOPIS",
    "firstname": "Nicolas"
  },
  {
    "score": 19,
    "lastname": "davy",
    "firstname": "marion"
  },
  {
    "score": 19,
    "lastname": "SARTHOU",
    "firstname": "Christine"
  },
  {
    "score": 19,
    "lastname": "Jarantowski",
    "firstname": "Emeline"
  },
  {
    "score": 19,
    "lastname": "GUERTIT",
    "firstname": "RABAH"
  },
  {
    "score": 19,
    "lastname": "Debord",
    "firstname": "Loïc"
  },
  {
    "score": 19,
    "lastname": "Couton",
    "firstname": "Clémentine"
  },
  {
    "score": 19,
    "lastname": "GRELOT",
    "firstname": "Jules"
  },
  {
    "score": 18,
    "lastname": "Bourblanc",
    "firstname": "Alexandre"
  },
  {
    "score": 18,
    "lastname": "FERREIRA",
    "firstname": "Gaëtan"
  },
  {
    "score": 18,
    "lastname": "LAFARGUE",
    "firstname": "Audrey"
  },
  {
    "score": 18,
    "lastname": "WIEREPANT",
    "firstname": "fabien"
  },
  {
    "score": 18,
    "lastname": "BIEBER",
    "firstname": "Elisabeth"
  },
  {
    "score": 18,
    "lastname": "Rotoullié",
    "firstname": "Florian"
  },
  {
    "score": 18,
    "lastname": "Vrignaud",
    "firstname": "Jérémy"
  },
  {
    "score": 18,
    "lastname": "LACROIX",
    "firstname": "Grégoire"
  },
  {
    "score": 18,
    "lastname": "Pontois",
    "firstname": "Hélène"
  },
  {
    "score": 18,
    "lastname": "Mounier",
    "firstname": "Jennifer"
  },
  {
    "score": 18,
    "lastname": "FIOL",
    "firstname": "Gaëlle"
  },
  {
    "score": 18,
    "lastname": "Bretel",
    "firstname": "Nathalie"
  },
  {
    "score": 18,
    "lastname": "AUDRAS",
    "firstname": "Raphaël"
  },
  {
    "score": 18,
    "lastname": "FAVRETTO",
    "firstname": "Julie"
  },
  {
    "score": 18,
    "lastname": "JOULAIN",
    "firstname": "Christophe"
  },
  {
    "score": 18,
    "lastname": "Burel",
    "firstname": "Maeva"
  },
  {
    "score": 18,
    "lastname": "MAURY",
    "firstname": "Damien"
  },
  {
    "score": 17,
    "lastname": "GRIMAL",
    "firstname": "Théo"
  },
  {
    "score": 17,
    "lastname": "Bozon",
    "firstname": "Mathieu"
  },
  {
    "score": 17,
    "lastname": "CLOUTE",
    "firstname": "Laure"
  },
  {
    "score": 17,
    "lastname": "PAUTRAT",
    "firstname": "Romain"
  },
  {
    "score": 17,
    "lastname": "CASTANIER",
    "firstname": "Julien"
  },
  {
    "score": 17,
    "lastname": "Roussel",
    "firstname": "Jérémy"
  },
  {
    "score": 17,
    "lastname": "vanthournout",
    "firstname": "margaux"
  },
  {
    "score": 17,
    "lastname": "RICHARD",
    "firstname": "Damien"
  },
  {
    "score": 17,
    "lastname": "Bezard",
    "firstname": "Kevin"
  },
  {
    "score": 17,
    "lastname": "Le Picault",
    "firstname": "Glenn"
  },
  {
    "score": 17,
    "lastname": "Lahousse",
    "firstname": "Sébastien"
  },
  {
    "score": 17,
    "lastname": "cornet",
    "firstname": "jordan"
  },
  {
    "score": 17,
    "lastname": "sum",
    "firstname": "sylvie"
  },
  {
    "score": 17,
    "lastname": "ESNEE",
    "firstname": "Christophe"
  },
  {
    "score": 17,
    "lastname": "desreumaux",
    "firstname": "cyprien"
  },
  {
    "score": 17,
    "lastname": "BENTRAR",
    "firstname": "KARIMA"
  },
  {
    "score": 17,
    "lastname": "DUBOIS",
    "firstname": "Virginie"
  },
  {
    "score": 17,
    "lastname": "Prouteau",
    "firstname": "Elie"
  },
  {
    "score": 17,
    "lastname": "MATYJASIK",
    "firstname": "Stephane"
  },
  {
    "score": 17,
    "lastname": "DUSOULIER",
    "firstname": "Nicolas"
  },
  {
    "score": 17,
    "lastname": "Jérémie",
    "firstname": "Dunier"
  },
  {
    "score": 16,
    "lastname": "PEPIN",
    "firstname": "CECILE"
  },
  {
    "score": 16,
    "lastname": "BESNARD",
    "firstname": "Bérengère"
  },
  {
    "score": 16,
    "lastname": "Yankovich",
    "firstname": "Ksenia"
  },
  {
    "score": 16,
    "lastname": "Touleron",
    "firstname": "Jane"
  },
  {
    "score": 16,
    "lastname": "POURCHET",
    "firstname": "Juliette"
  },
  {
    "score": 16,
    "lastname": "Mengelle",
    "firstname": "Cécile"
  },
  {
    "score": 16,
    "lastname": "LESUEUR",
    "firstname": "Adrien"
  },
  {
    "score": 16,
    "lastname": "LAMBOLEZ",
    "firstname": "Quentin"
  },
  {
    "score": 16,
    "lastname": "DAROUSSIN",
    "firstname": "David"
  },
  {
    "score": 16,
    "lastname": "Gengembre",
    "firstname": "Rémi"
  },
  {
    "score": 16,
    "lastname": "BRICOUT",
    "firstname": "Maxime"
  },
  {
    "score": 16,
    "lastname": "Launay",
    "firstname": "Julien"
  },
  {
    "score": 16,
    "lastname": "moussier",
    "firstname": "isabelle"
  },
  {
    "score": 16,
    "lastname": "MELANI",
    "firstname": "CELINE"
  },
  {
    "score": 16,
    "lastname": "BERMOND",
    "firstname": "Clément"
  },
  {
    "score": 16,
    "lastname": "Bacoul",
    "firstname": "Mélissa"
  },
  {
    "score": 16,
    "lastname": "GOUY",
    "firstname": "Karine"
  },
  {
    "score": 16,
    "lastname": "Souyad",
    "firstname": "Samia"
  },
  {
    "score": 16,
    "lastname": "ROSA",
    "firstname": "Marion"
  },
  {
    "score": 16,
    "lastname": "Couchez",
    "firstname": "Coline"
  },
  {
    "score": 16,
    "lastname": "schulz",
    "firstname": "marion"
  },
  {
    "score": 16,
    "lastname": "LEFEVRE",
    "firstname": "STEPHANIE"
  },
  {
    "score": 16,
    "lastname": "BELMIR",
    "firstname": "Morgane"
  },
  {
    "score": 16,
    "lastname": "berguin",
    "firstname": "karine"
  },
  {
    "score": 16,
    "lastname": "SUN",
    "firstname": "YIBO"
  },
  {
    "score": 16,
    "lastname": "NOVAIS",
    "firstname": "Geoffrey"
  },
  {
    "score": 16,
    "lastname": "OMONT",
    "firstname": "Vianney"
  },
  {
    "score": 16,
    "lastname": "Lancelevé",
    "firstname": "David"
  },
  {
    "score": 16,
    "lastname": "KHITER",
    "firstname": "SOUHILA"
  },
  {
    "score": 16,
    "lastname": "Taugain",
    "firstname": "Guillaume"
  },
  {
    "score": 16,
    "lastname": "BELHACHEMI",
    "firstname": "Maher"
  },
  {
    "score": 15,
    "lastname": "Lapierre",
    "firstname": "Romain"
  },
  {
    "score": 15,
    "lastname": "Bouve",
    "firstname": "Titouan"
  },
  {
    "score": 15,
    "lastname": "quesney",
    "firstname": "Matthieu"
  },
  {
    "score": 15,
    "lastname": "BECAAS",
    "firstname": "Ludovic"
  },
  {
    "score": 15,
    "lastname": "Dupont",
    "firstname": "Carole"
  },
  {
    "score": 15,
    "lastname": "DASSONVILLE",
    "firstname": "laetitia"
  },
  {
    "score": 15,
    "lastname": "Jeandin",
    "firstname": "Benjamin"
  },
  {
    "score": 15,
    "lastname": "Way",
    "firstname": "Kévin"
  },
  {
    "score": 15,
    "lastname": "Gallay",
    "firstname": "Aurélie"
  },
  {
    "score": 15,
    "lastname": "portier",
    "firstname": "thomas"
  },
  {
    "score": 15,
    "lastname": "VASSÉ-CARVALHO MORAIS",
    "firstname": "Sunsee"
  },
  {
    "score": 15,
    "lastname": "Farges",
    "firstname": "Florent"
  },
  {
    "score": 15,
    "lastname": "Jonathan",
    "firstname": "PAULANDRE"
  },
  {
    "score": 15,
    "lastname": "majeed",
    "firstname": "hisham"
  },
  {
    "score": 15,
    "lastname": "GASPARD",
    "firstname": "Romain"
  },
  {
    "score": 15,
    "lastname": "Latil",
    "firstname": "didier"
  },
  {
    "score": 15,
    "lastname": "SANTOS",
    "firstname": "PASCALE"
  },
  {
    "score": 15,
    "lastname": "ALADENISE",
    "firstname": "Isabelle"
  },
  {
    "score": 15,
    "lastname": "Levasseur",
    "firstname": "Catherine"
  },
  {
    "score": 15,
    "lastname": "ROBYN",
    "firstname": "Lucile"
  },
  {
    "score": 15,
    "lastname": "BAGLIN",
    "firstname": "Margaux"
  },
  {
    "score": 15,
    "lastname": "adel",
    "firstname": "nebil"
  },
  {
    "score": 15,
    "lastname": "kosiak",
    "firstname": "tom"
  },
  {
    "score": 15,
    "lastname": "KHENDEK",
    "firstname": "FLEUR"
  },
  {
    "score": 15,
    "lastname": "Rahmi",
    "firstname": "Meriem"
  },
  {
    "score": 15,
    "lastname": "Bannour",
    "firstname": "Hakim"
  },
  {
    "score": 15,
    "lastname": "Colomb",
    "firstname": "Laetitia"
  },
  {
    "score": 15,
    "lastname": "BLAMOUTIER",
    "firstname": "Yoran"
  },
  {
    "score": 15,
    "lastname": "DE NEIVA",
    "firstname": "carlos"
  },
  {
    "score": 15,
    "lastname": "Guyon",
    "firstname": "Gilles"
  },
  {
    "score": 15,
    "lastname": "scolca",
    "firstname": "benoit"
  },
  {
    "score": 14,
    "lastname": "CARRARA",
    "firstname": "Jérôme"
  },
  {
    "score": 14,
    "lastname": "Carlin",
    "firstname": "Cyril"
  },
  {
    "score": 14,
    "lastname": "SOURDIN",
    "firstname": "isabelle"
  },
  {
    "score": 14,
    "lastname": "Lalande",
    "firstname": "Flavie"
  },
  {
    "score": 14,
    "lastname": "RENAUD",
    "firstname": "Marylène"
  },
  {
    "score": 14,
    "lastname": "LAUHIRAT-BIDOT",
    "firstname": "Virginie"
  },
  {
    "score": 14,
    "lastname": "Gueguen",
    "firstname": "Anna"
  },
  {
    "score": 14,
    "lastname": "DENIS",
    "firstname": "Cédric"
  },
  {
    "score": 14,
    "lastname": "Houlbreque",
    "firstname": "Adrien"
  },
  {
    "score": 14,
    "lastname": "Rapicault",
    "firstname": "Léa"
  },
  {
    "score": 14,
    "lastname": "Roy",
    "firstname": "Gauthier"
  },
  {
    "score": 14,
    "lastname": "Landeau",
    "firstname": "Didier"
  },
  {
    "score": 14,
    "lastname": "boutrin",
    "firstname": "vanessa"
  },
  {
    "score": 14,
    "lastname": "Sarrah",
    "firstname": "Benaoura"
  },
  {
    "score": 14,
    "lastname": "Amazian",
    "firstname": "Jamal"
  },
  {
    "score": 14,
    "lastname": "YESSAD",
    "firstname": "Aissa"
  },
  {
    "score": 14,
    "lastname": "Legrand",
    "firstname": "Flavie"
  },
  {
    "score": 14,
    "lastname": "maurice",
    "firstname": "sonia"
  },
  {
    "score": 14,
    "lastname": "SAVATIER",
    "firstname": "thony"
  },
  {
    "score": 14,
    "lastname": "Sarrazyn",
    "firstname": "Michael"
  },
  {
    "score": 14,
    "lastname": "SUSIN",
    "firstname": "Cécile"
  },
  {
    "score": 14,
    "lastname": "ALLEMAND",
    "firstname": "Clémence"
  },
  {
    "score": 14,
    "lastname": "Guerveno",
    "firstname": "Yann"
  },
  {
    "score": 14,
    "lastname": "Delrot",
    "firstname": "Manon"
  },
  {
    "score": 14,
    "lastname": "Fougeroud",
    "firstname": "Laurie"
  },
  {
    "score": 14,
    "lastname": "Da Silva",
    "firstname": "Corentin"
  },
  {
    "score": 14,
    "lastname": "Labat",
    "firstname": "Xavier"
  },
  {
    "score": 14,
    "lastname": "Poitiers",
    "firstname": "Mathieu"
  },
  {
    "score": 14,
    "lastname": "Dharmapurikar",
    "firstname": "Anagha"
  },
  {
    "score": 14,
    "lastname": "Estival",
    "firstname": "Stéphane"
  },
  {
    "score": 14,
    "lastname": "Fleisch",
    "firstname": "Florian"
  },
  {
    "score": 14,
    "lastname": "CHAPUIS",
    "firstname": "Axel"
  },
  {
    "score": 14,
    "lastname": "rojon",
    "firstname": "tommy"
  },
  {
    "score": 13,
    "lastname": "pinaquy",
    "firstname": "diane"
  },
  {
    "score": 13,
    "lastname": "KIS",
    "firstname": "STANISLAS"
  },
  {
    "score": 13,
    "lastname": "PARENT",
    "firstname": "Edwige"
  },
  {
    "score": 13,
    "lastname": "Bergeot",
    "firstname": "Aurelie"
  },
  {
    "score": 13,
    "lastname": "DUMOULIN",
    "firstname": "Loïck"
  },
  {
    "score": 13,
    "lastname": "FLEURY",
    "firstname": "Emmanuel"
  },
  {
    "score": 13,
    "lastname": "CROGUENNEC",
    "firstname": "Nadine"
  },
  {
    "score": 13,
    "lastname": "ROLLING",
    "firstname": "Nolwenn"
  },
  {
    "score": 13,
    "lastname": "Manéré",
    "firstname": "Yeshua"
  },
  {
    "score": 13,
    "lastname": "Odinot",
    "firstname": "Matthieu"
  },
  {
    "score": 13,
    "lastname": "HALLOUIN",
    "firstname": "Mélanie"
  },
  {
    "score": 13,
    "lastname": "SICHOUC",
    "firstname": "Donna"
  },
  {
    "score": 13,
    "lastname": "Goumarre",
    "firstname": "Emilie"
  },
  {
    "score": 13,
    "lastname": "Delcros",
    "firstname": "Mathilde"
  },
  {
    "score": 13,
    "lastname": "THOMAS",
    "firstname": "Céline"
  },
  {
    "score": 13,
    "lastname": "NAGLER",
    "firstname": "Amandine"
  },
  {
    "score": 13,
    "lastname": "BASCHET",
    "firstname": "Samuel"
  },
  {
    "score": 13,
    "lastname": "deron",
    "firstname": "rémy"
  },
  {
    "score": 13,
    "lastname": "ROMAGNE",
    "firstname": "NOLWENN"
  },
  {
    "score": 13,
    "lastname": "HUET",
    "firstname": "Magali"
  },
  {
    "score": 13,
    "lastname": "GUICHERD-CALLIN",
    "firstname": "Thomas"
  },
  {
    "score": 13,
    "lastname": "GIRARDIN",
    "firstname": "PRISCILLIA"
  },
  {
    "score": 13,
    "lastname": "HENNEBERT",
    "firstname": "REMI"
  },
  {
    "score": 13,
    "lastname": "isikli",
    "firstname": "sarah"
  },
  {
    "score": 13,
    "lastname": "QUIGNON",
    "firstname": "vincent"
  },
  {
    "score": 13,
    "lastname": "Pringalle",
    "firstname": "Théo"
  },
  {
    "score": 13,
    "lastname": "gabanou",
    "firstname": "myriam"
  },
  {
    "score": 13,
    "lastname": "Chourar",
    "firstname": "Hichem"
  },
  {
    "score": 13,
    "lastname": "TABA",
    "firstname": "CAROLINE"
  },
  {
    "score": 13,
    "lastname": "Callewaert",
    "firstname": "Vincent"
  },
  {
    "score": 13,
    "lastname": "ROUX",
    "firstname": "Sylvain"
  },
  {
    "score": 13,
    "lastname": "KLAJMAN",
    "firstname": "Pascale"
  },
  {
    "score": 13,
    "lastname": "PAULANDRÉ",
    "firstname": "Amandine"
  },
  {
    "score": 12,
    "lastname": "Tahé",
    "firstname": "Mélanie"
  },
  {
    "score": 12,
    "lastname": "GURPEGUI",
    "firstname": "Felix"
  },
  {
    "score": 12,
    "lastname": "thieulin",
    "firstname": "wilfrid"
  },
  {
    "score": 12,
    "lastname": "THAVISOUK",
    "firstname": "Daniel"
  },
  {
    "score": 12,
    "lastname": "Dutilh",
    "firstname": "Alan"
  },
  {
    "score": 12,
    "lastname": "GAIA",
    "firstname": "SANDRINE"
  },
  {
    "score": 12,
    "lastname": "bertin",
    "firstname": "stephane"
  },
  {
    "score": 12,
    "lastname": "DUROT",
    "firstname": "Alexandre"
  },
  {
    "score": 12,
    "lastname": "DELGRANGE",
    "firstname": "Joffrey"
  },
  {
    "score": 12,
    "lastname": "LEIZOUR",
    "firstname": "Maëva"
  },
  {
    "score": 12,
    "lastname": "Tiercelin",
    "firstname": "Stéphanie"
  },
  {
    "score": 12,
    "lastname": "CAPUT",
    "firstname": "Elise"
  },
  {
    "score": 12,
    "lastname": "ZAROUGUI",
    "firstname": "soukaina"
  },
  {
    "score": 12,
    "lastname": "GRATALOUP",
    "firstname": "Boris"
  },
  {
    "score": 12,
    "lastname": "LAURENT",
    "firstname": "Stéphanie"
  },
  {
    "score": 12,
    "lastname": "DULCAMARA",
    "firstname": "Elisa"
  },
  {
    "score": 12,
    "lastname": "lentheric",
    "firstname": "david"
  },
  {
    "score": 12,
    "lastname": "LERONSEUR",
    "firstname": "Caroline"
  },
  {
    "score": 12,
    "lastname": "Millet",
    "firstname": "Marine"
  },
  {
    "score": 12,
    "lastname": "COUTURE",
    "firstname": "Alexandre"
  },
  {
    "score": 12,
    "lastname": "Kevin",
    "firstname": "Deslandes"
  },
  {
    "score": 12,
    "lastname": "Gloaguen",
    "firstname": "Arthur"
  },
  {
    "score": 12,
    "lastname": "PENDEZEC",
    "firstname": "Jean-françois"
  },
  {
    "score": 12,
    "lastname": "Bigo",
    "firstname": "Charles"
  },
  {
    "score": 12,
    "lastname": "GAFFIE",
    "firstname": "JEAN-MICHEL"
  },
  {
    "score": 12,
    "lastname": "Guillaume",
    "firstname": "Grissault"
  },
  {
    "score": 12,
    "lastname": "THIBAULT",
    "firstname": "Virginie"
  },
  {
    "score": 12,
    "lastname": "MARTINEZ",
    "firstname": "RENI"
  },
  {
    "score": 12,
    "lastname": "REGNIES",
    "firstname": "Sylvain"
  },
  {
    "score": 12,
    "lastname": "Fanchon",
    "firstname": "Axel"
  },
  {
    "score": 12,
    "lastname": "Bernard",
    "firstname": "Julien"
  },
  {
    "score": 12,
    "lastname": "Mayo",
    "firstname": "Jonathan"
  },
  {
    "score": 12,
    "lastname": "poiraud",
    "firstname": "veronique"
  },
  {
    "score": 12,
    "lastname": "CARTON",
    "firstname": "Pascal"
  },
  {
    "score": 12,
    "lastname": "petithomme",
    "firstname": "xavier"
  },
  {
    "score": 12,
    "lastname": "Varenne",
    "firstname": "benjamin"
  },
  {
    "score": 12,
    "lastname": "brunet",
    "firstname": "melyna"
  },
  {
    "score": 12,
    "lastname": "RICHARD",
    "firstname": "Joan"
  },
  {
    "score": 12,
    "lastname": "Chatziioannou",
    "firstname": "Christina"
  },
  {
    "score": 12,
    "lastname": "LE GALLOUDEC",
    "firstname": "KENNY"
  },
  {
    "score": 12,
    "lastname": "Platis",
    "firstname": "Andréas"
  },
  {
    "score": 12,
    "lastname": "alfonsi",
    "firstname": "laurine"
  },
  {
    "score": 12,
    "lastname": "ETIENNE GAUDIN",
    "firstname": "Anne Marie"
  },
  {
    "score": 12,
    "lastname": "bardet",
    "firstname": "romain"
  },
  {
    "score": 12,
    "lastname": "LE NAOUR",
    "firstname": "KEVIN"
  },
  {
    "score": 12,
    "lastname": "Paressant",
    "firstname": "Quentin"
  },
  {
    "score": 12,
    "lastname": "Nadarajah",
    "firstname": "Ragulan"
  },
  {
    "score": 12,
    "lastname": "SENOVILLE",
    "firstname": "Thomas"
  },
  {
    "score": 12,
    "lastname": "Bombard",
    "firstname": "Regis"
  },
  {
    "score": 12,
    "lastname": "Bricaud",
    "firstname": "Jerome"
  },
  {
    "score": 12,
    "lastname": "CHEVALLIER",
    "firstname": "Alban"
  },
  {
    "score": 12,
    "lastname": "Jobin",
    "firstname": "Guillaume"
  },
  {
    "score": 12,
    "lastname": "Ith",
    "firstname": "Jeremy"
  },
  {
    "score": 12,
    "lastname": "Nougaret",
    "firstname": "Audrey"
  },
  {
    "score": 11,
    "lastname": "CANAVAGGIA",
    "firstname": "Eric"
  },
  {
    "score": 11,
    "lastname": "Gignon",
    "firstname": "Antoine"
  },
  {
    "score": 11,
    "lastname": "Veniat",
    "firstname": "Guillaume"
  },
  {
    "score": 11,
    "lastname": "BENANANE",
    "firstname": "YASMINA"
  },
  {
    "score": 11,
    "lastname": "Canipel",
    "firstname": "Louise"
  },
  {
    "score": 11,
    "lastname": "Mathias",
    "firstname": "Nadine"
  },
  {
    "score": 11,
    "lastname": "LANOE",
    "firstname": "Soizic"
  },
  {
    "score": 11,
    "lastname": "arnaudas",
    "firstname": "Carole"
  },
  {
    "score": 11,
    "lastname": "Menuge",
    "firstname": "Michael"
  },
  {
    "score": 11,
    "lastname": "TOTIER",
    "firstname": "Pierre"
  },
  {
    "score": 11,
    "lastname": "Audo",
    "firstname": "Carmen"
  },
  {
    "score": 11,
    "lastname": "Payet",
    "firstname": "Simon"
  },
  {
    "score": 11,
    "lastname": "WEBER",
    "firstname": "Philippe"
  },
  {
    "score": 11,
    "lastname": "GERMAIN",
    "firstname": "christele"
  },
  {
    "score": 11,
    "lastname": "CHATARD",
    "firstname": "Florian"
  },
  {
    "score": 11,
    "lastname": "STEPHANIE",
    "firstname": "BERTRE"
  },
  {
    "score": 11,
    "lastname": "GOMEZ",
    "firstname": "Thomas"
  },
  {
    "score": 11,
    "lastname": "Loustau",
    "firstname": "Vincent"
  },
  {
    "score": 11,
    "lastname": "Musseau",
    "firstname": "Laurent"
  },
  {
    "score": 11,
    "lastname": "Sabathier",
    "firstname": "Sylvain"
  },
  {
    "score": 11,
    "lastname": "Garcia",
    "firstname": "Damien"
  },
  {
    "score": 11,
    "lastname": "VANDEVOORDE",
    "firstname": "shirley"
  },
  {
    "score": 11,
    "lastname": "VERNET",
    "firstname": "Lucile"
  },
  {
    "score": 11,
    "lastname": "BONOTTI",
    "firstname": "Nicolas"
  },
  {
    "score": 11,
    "lastname": "RAULT",
    "firstname": "WILLIAM"
  },
  {
    "score": 11,
    "lastname": "Balembits",
    "firstname": "Olivier"
  },
  {
    "score": 11,
    "lastname": "VALENTE",
    "firstname": "Fabio"
  },
  {
    "score": 11,
    "lastname": "tazi",
    "firstname": "khadija"
  },
  {
    "score": 11,
    "lastname": "crochet",
    "firstname": "Benjamin"
  },
  {
    "score": 11,
    "lastname": "Tournant",
    "firstname": "Valentine"
  },
  {
    "score": 11,
    "lastname": "DUPARAY",
    "firstname": "Vanessa"
  },
  {
    "score": 11,
    "lastname": "ZEGGAI",
    "firstname": "Essaid"
  },
  {
    "score": 11,
    "lastname": "BONNASSERRE",
    "firstname": "SANDRINE"
  },
  {
    "score": 11,
    "lastname": "Descours",
    "firstname": "Berengere"
  },
  {
    "score": 11,
    "lastname": "Brunet",
    "firstname": "Felicien"
  },
  {
    "score": 11,
    "lastname": "CHARAF",
    "firstname": "Fatima"
  },
  {
    "score": 11,
    "lastname": "Figadere",
    "firstname": "Mickael"
  },
  {
    "score": 11,
    "lastname": "El meftahi",
    "firstname": "Abdelaali"
  },
  {
    "score": 11,
    "lastname": "TAUZIN",
    "firstname": "Cyrille"
  },
  {
    "score": 11,
    "lastname": "Lloret",
    "firstname": "Virginie"
  },
  {
    "score": 11,
    "lastname": "Dos Santos",
    "firstname": "Manuel"
  },
  {
    "score": 11,
    "lastname": "Irzenski",
    "firstname": "Paul"
  },
  {
    "score": 11,
    "lastname": "Babinet",
    "firstname": "Cyrille"
  },
  {
    "score": 10,
    "lastname": "bibens",
    "firstname": "Sandra"
  },
  {
    "score": 10,
    "lastname": "phillipot",
    "firstname": "ewen"
  },
  {
    "score": 10,
    "lastname": "LE MOIGNE",
    "firstname": "Guillaume"
  },
  {
    "score": 10,
    "lastname": "DEBEAUVAIS",
    "firstname": "STEPHANIE"
  },
  {
    "score": 10,
    "lastname": "DELATTRE",
    "firstname": "Romain"
  },
  {
    "score": 10,
    "lastname": "LOSTETTER",
    "firstname": "Jérémy"
  },
  {
    "score": 10,
    "lastname": "poiraud",
    "firstname": "veronique"
  },
  {
    "score": 10,
    "lastname": "MOULIN",
    "firstname": "Marie-pierre"
  },
  {
    "score": 10,
    "lastname": "IZMAR",
    "firstname": "Chafika"
  },
  {
    "score": 10,
    "lastname": "Jacquemont",
    "firstname": "Antoine"
  },
  {
    "score": 10,
    "lastname": "tampon",
    "firstname": "sarah"
  },
  {
    "score": 10,
    "lastname": "panciocco",
    "firstname": "sandrine"
  },
  {
    "score": 10,
    "lastname": "panciocco",
    "firstname": "sandrine"
  },
  {
    "score": 10,
    "lastname": "Borgne",
    "firstname": "Sylvie"
  },
  {
    "score": 10,
    "lastname": "Szika",
    "firstname": "Maxime"
  },
  {
    "score": 10,
    "lastname": "Chambard",
    "firstname": "Mathieu"
  },
  {
    "score": 10,
    "lastname": "Bodiot",
    "firstname": "Nathalie"
  },
  {
    "score": 10,
    "lastname": "DEBEVERE",
    "firstname": "FRANCOIS XAVIER"
  },
  {
    "score": 10,
    "lastname": "cauvin",
    "firstname": "nathalie"
  },
  {
    "score": 10,
    "lastname": "Touzalin",
    "firstname": "Jason"
  },
  {
    "score": 10,
    "lastname": "Gravaud",
    "firstname": "Alexandre"
  },
  {
    "score": 10,
    "lastname": "CARON",
    "firstname": "Christophe"
  },
  {
    "score": 10,
    "lastname": "taillard",
    "firstname": "adele"
  },
  {
    "score": 10,
    "lastname": "DOMBASI-VUVU",
    "firstname": "ESPERANCE"
  },
  {
    "score": 10,
    "lastname": "BLOND",
    "firstname": "SANDRA"
  },
  {
    "score": 10,
    "lastname": "JUVIER",
    "firstname": "Vladimir"
  },
  {
    "score": 10,
    "lastname": "Sabanowski",
    "firstname": "Simon"
  },
  {
    "score": 10,
    "lastname": "LAMBERT",
    "firstname": "Thomas"
  },
  {
    "score": 10,
    "lastname": "Broudic",
    "firstname": "Sylvain"
  },
  {
    "score": 10,
    "lastname": "Meyer",
    "firstname": "Florian"
  },
  {
    "score": 10,
    "lastname": "GARDES",
    "firstname": "Michel"
  },
  {
    "score": 10,
    "lastname": "ZUCK",
    "firstname": "MARC"
  },
  {
    "score": 10,
    "lastname": "ABARKACH",
    "firstname": "Amine"
  },
  {
    "score": 10,
    "lastname": "Junqua",
    "firstname": "Nathalie"
  },
  {
    "score": 10,
    "lastname": "AIT ICHOU",
    "firstname": "Jawad"
  },
  {
    "score": 10,
    "lastname": "meirieu",
    "firstname": "aurélie"
  },
  {
    "score": 10,
    "lastname": "GENGEMBRE",
    "firstname": "ERIC"
  },
  {
    "score": 10,
    "lastname": "VASSEUR",
    "firstname": "Christelle"
  },
  {
    "score": 10,
    "lastname": "bidau",
    "firstname": "marie-sylvie"
  },
  {
    "score": 10,
    "lastname": "SAURON",
    "firstname": "THIERRY"
  },
  {
    "score": 10,
    "lastname": "Buhannic",
    "firstname": "Guillaume"
  },
  {
    "score": 10,
    "lastname": "Lievre",
    "firstname": "Clarisse"
  },
  {
    "score": 10,
    "lastname": "BUSCAIL",
    "firstname": "Romain"
  },
  {
    "score": 9,
    "lastname": "CARRARA",
    "firstname": "MATTHIEU"
  },
  {
    "score": 9,
    "lastname": "CROST",
    "firstname": "Lionel"
  },
  {
    "score": 9,
    "lastname": "HAURE",
    "firstname": "Corinne"
  },
  {
    "score": 9,
    "lastname": "Morio",
    "firstname": "Gabriel"
  },
  {
    "score": 9,
    "lastname": "Mourier",
    "firstname": "Matthieu"
  },
  {
    "score": 9,
    "lastname": "LAFFONT",
    "firstname": "Emilie"
  },
  {
    "score": 9,
    "lastname": "Hamadi",
    "firstname": "Kamel"
  },
  {
    "score": 9,
    "lastname": "Virginie",
    "firstname": "RUBIO"
  },
  {
    "score": 9,
    "lastname": "LE BARS",
    "firstname": "Marie Annick"
  },
  {
    "score": 9,
    "lastname": "ROUABAH",
    "firstname": "Malik"
  },
  {
    "score": 9,
    "lastname": "GERVAIS",
    "firstname": "Vanessa"
  },
  {
    "score": 9,
    "lastname": "BIEHLMANN",
    "firstname": "Nicolas"
  },
  {
    "score": 9,
    "lastname": "TORTEL",
    "firstname": "SARAH"
  },
  {
    "score": 9,
    "lastname": "N'GUESSAN",
    "firstname": "Marius"
  },
  {
    "score": 9,
    "lastname": "Traore",
    "firstname": "Guedio"
  },
  {
    "score": 9,
    "lastname": "Vicente",
    "firstname": "Rémy"
  },
  {
    "score": 9,
    "lastname": "LEMEE",
    "firstname": "CAMILLE"
  },
  {
    "score": 9,
    "lastname": "METRAS",
    "firstname": "Jean CHARLES"
  },
  {
    "score": 9,
    "lastname": "BLOSSIER",
    "firstname": "Dimitri"
  },
  {
    "score": 9,
    "lastname": "Decloquement",
    "firstname": "Alexandre"
  },
  {
    "score": 9,
    "lastname": "LOURAÏCHI KROTNI",
    "firstname": "Ilhem"
  },
  {
    "score": 9,
    "lastname": "Lacomme",
    "firstname": "David"
  },
  {
    "score": 9,
    "lastname": "Constant",
    "firstname": "Florian"
  },
  {
    "score": 9,
    "lastname": "Castet",
    "firstname": "Laurent"
  },
  {
    "score": 9,
    "lastname": "Barrault",
    "firstname": "Gwen"
  },
  {
    "score": 9,
    "lastname": "Mouster",
    "firstname": "laurent"
  },
  {
    "score": 9,
    "lastname": "Sivet",
    "firstname": "Anthony"
  },
  {
    "score": 9,
    "lastname": "LEFEVRE",
    "firstname": "Didier"
  },
  {
    "score": 9,
    "lastname": "Barande",
    "firstname": "Victoria"
  },
  {
    "score": 9,
    "lastname": "Ben amar",
    "firstname": "Raed"
  },
  {
    "score": 8,
    "lastname": "Tahé",
    "firstname": "Mélanie"
  },
  {
    "score": 8,
    "lastname": "Barrier",
    "firstname": "Daniel"
  },
  {
    "score": 8,
    "lastname": "Denaene",
    "firstname": "Stephane"
  },
  {
    "score": 8,
    "lastname": "VANO",
    "firstname": "Laetitia"
  },
  {
    "score": 8,
    "lastname": "GRZELINSKI",
    "firstname": "christophe"
  },
  {
    "score": 8,
    "lastname": "Eliot",
    "firstname": "Jessica"
  },
  {
    "score": 8,
    "lastname": "Achour",
    "firstname": "Yassine"
  },
  {
    "score": 8,
    "lastname": "MARQUES",
    "firstname": "Sabrina"
  },
  {
    "score": 8,
    "lastname": "Droisy",
    "firstname": "Peter"
  },
  {
    "score": 8,
    "lastname": "Monnier",
    "firstname": "Mael"
  },
  {
    "score": 8,
    "lastname": "PARMENTIER",
    "firstname": "DAMIEN"
  },
  {
    "score": 8,
    "lastname": "BOUATTOU",
    "firstname": "Houria"
  },
  {
    "score": 8,
    "lastname": "schmidt",
    "firstname": "franck"
  },
  {
    "score": 8,
    "lastname": "Grebert",
    "firstname": "Quentin"
  },
  {
    "score": 8,
    "lastname": "Henriques",
    "firstname": "Victor"
  },
  {
    "score": 8,
    "lastname": "Aroua",
    "firstname": "Salem"
  },
  {
    "score": 8,
    "lastname": "Rachedi",
    "firstname": "Zouina"
  },
  {
    "score": 8,
    "lastname": "Gourmelen",
    "firstname": "Florian"
  },
  {
    "score": 7,
    "lastname": "Gervais",
    "firstname": "Léonie"
  },
  {
    "score": 7,
    "lastname": "VAN DEN ABEELE",
    "firstname": "JEAN-LOUIS"
  },
  {
    "score": 7,
    "lastname": "Errizgui",
    "firstname": "Mohamed"
  },
  {
    "score": 7,
    "lastname": "Gevaert",
    "firstname": "Fabien"
  },
  {
    "score": 7,
    "lastname": "Conca",
    "firstname": "Hélène"
  },
  {
    "score": 7,
    "lastname": "SEBIANE",
    "firstname": "HAMZA"
  },
  {
    "score": 7,
    "lastname": "derias",
    "firstname": "hichem"
  },
  {
    "score": 6,
    "lastname": "Peguilhan",
    "firstname": "Alexandre"
  }
]

export default ranking;
